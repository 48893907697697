import Vue from 'vue'

import VeLine from 'v-charts/lib/line.common'
Vue.component(VeLine.name,VeLine)

import VePie from 'v-charts/lib/pie.common'
Vue.component(VePie.name,VePie)

import VeRing from 'v-charts/lib/ring.common'
Vue.component(VeRing.name,VeRing)

import VeHistogram from 'v-charts/lib/histogram.common'
Vue.component(VeHistogram.name,VeHistogram)