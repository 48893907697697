<template>
    <a-layout class="main-layout">
        <a-layout-sider :trigger="null" collapsible v-model="collapsed" class="layout-sider bg">
            <div class="logo" >
                <img src="../../../public/image/indexLogo.png" alt="" v-if="!collapsed" />
                <img src="../../../public/image/sLogo.png" alt="" v-else style="width:40px;height:42px"/>
            </div>
            <a-menu mode="inline" :defaultSelectedKeys="['/home/index']" :selectedKeys="[$route.fullPath]" class="menuList" @select="menuSelect" v-if="$store.getters.permission==1">
                <a-menu-item key="/home/index">
                    <a-icon type="home" class="aicon"/>
                    <!-- <i class="icon home"></i> -->
                    <span>首页</span>
                </a-menu-item>
                <a-menu-item key="/home/task">
                    <a-icon type="upload" class="aicon"/>
                    <!-- <i class="icon taskIcon"></i> -->
                    <span>发布任务</span>
                </a-menu-item>
                <a-menu-item key="/home/messages">
                    <a-icon type="file-text" class="aicon"/>
                    <!-- <i class="icon message"></i> -->
                    <span >消息</span>
                </a-menu-item>
                <a-menu-item key="/home/setting">
                    <a-icon type="setting" class="aicon"/>
                    <!-- <i class="icon setting"></i> -->
                    <span>设置</span>
                </a-menu-item>
            </a-menu>
            <a-menu mode="inline" :defaultSelectedKeys="['/home/AllTask']" :selectedKeys="[$route.fullPath]" class="menuList" @select="menuSelect" v-if="$store.getters.permission==2">
                <a-menu-item key="/home/AllTask">
                    <i class="icon taskIcon"></i>
                    <span>所有任务</span>
                </a-menu-item>
            </a-menu>
            <div class="sideLoginOut" @click="logout"><div class="loginOutIcon"></div><span v-if="!collapsed">退出</span></div>
        </a-layout-sider>
        <a-layout>
        <a-layout-header class="topHeader">
            <div>
                <a-icon
                    class="trigger"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    @click="()=> collapsed = !collapsed"
                    :style="{ marginLeft: '10px' }"
                />
            </div>
            <div class="mainLink">
                <div class="active">系统管理</div>
                <div @click="pathToSys">考试系统</div>
                <div @click="pathToPc">实践科目</div>
                <div @click="pathToAnaly">解析管理</div>
            </div>
            <div>
                <div class="headerMes">
                    <a-badge dot>
                        <a-icon type="bell" />
                    </a-badge>
                </div>
                <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link">
                        {{$store.getters.userInfo.username}}&nbsp;&nbsp;&nbsp;&nbsp;<a-icon type="down" />
                    </a>
                <a-menu slot="overlay">
                <a-menu-item>
                    <a @click="logout">退出登录</a>
                </a-menu-item>
                </a-menu>
          </a-dropdown>
            </div>


        </a-layout-header>
        <a-layout-content
            :style="{ minHeight: '280px',overflowY:'auto',overflowX:'hidden',height:'100%'}"
        >
            <router-view></router-view>
        </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import { removeUserInfo, removeToken, removePermission } from '@/libs/auth'
export default {
    name:'layout',
    data(){
        return{
            collapsed:false
        }
    },
    methods:{
        menuSelect({key}){
            this.$router.push(key)
        },
        logout(){
            removeUserInfo()
            removeToken()
            removePermission()
            this.$store.commit('setUserInfo','')
            localStorage.removeItem('routelist')
            this.$store.commit('removeAllRoute')
            this.$router.push('/login')
        },
        pathToSys(){
            localStorage.removeItem('routelist')
            this.$store.commit('removeAllRoute')
            this.$router.push('/exam');
        },
        pathToPc(){
            localStorage.removeItem('routelist')
            this.$store.commit('removeAllRoute')
            this.$router.push('/practice');
        },
        pathToAnaly(){
            localStorage.removeItem('routelist')
            this.$store.commit('removeAllRoute')
            this.$router.push('/analysis');
        }
    }
}
</script>
<style lang="less" scoped>
.sideLoginOut{
    text-align: center;
    color: #fff;
    position: absolute;
    width: 100%;
    bottom: 40px;
    cursor: pointer;
    .loginOutIcon{
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        background: url(../../../public/image/loginOut.png);
        background-size: cover;
        background-repeat: no-repeat
    }
    span{
        font-size: 18px;
        margin-left: 10px;
        letter-spacing: 2px
    }
}
.aicon{font-size: 20px;}
.ant-menu-item-selected{
    color: #fff;
}
</style>