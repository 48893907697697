
import { getUserInfo, getPermission } from '@/libs/auth'

export default {
	state: {
		userInfo: getUserInfo() ? JSON.parse(getUserInfo()) : '',
		permission: getPermission() ? JSON.parse(getPermission()): '',//1:管理员 2：学员
		routelist: localStorage.getItem('routelist')?JSON.parse(localStorage.getItem('routelist')):[]
	},
	getters:{
		userInfo: state => {
            return state.userInfo
		},
		permission: state => {
			return state.permission
		},
		routelist: state =>{
			return state.routelist 
		}
	},
	mutations: {
		setUserInfo(state, data) {
			state.userInfo = data;
		},
		setPermission( state, data ){
			state.permission = data
		},
		setRouteList ( state, data) {
			state.routelist.push(data)
			localStorage.setItem('routelist',JSON.stringify(state.routelist))
		},
		removeRoute (state, data){
			let index = state.routelist.findIndex(el => el.key == data.key)
			state.routelist.splice(index, 1)
			localStorage.setItem('routelist',JSON.stringify(state.routelist))
		},
		removeAllRoute (state){
			state.routelist = []
		}
	},
	actions: {
		
	}
}