import Cookies from 'js-cookie'

const expires = new Date(new Date().getTime() + 60*60*24*7*1000);
export function setUserInfo(userInfo) {
	Cookies.set('userInfo', JSON.stringify(userInfo), {expires})
}
export function getUserInfo(){
	return Cookies.get('userInfo')
}
export function removeUserInfo(){
    return Cookies.remove('userInfo')
}


export function setToken(token) {
	Cookies.set('token', token, {expires})
}
export function getToken(){
	return Cookies.get('token')
}

export function removeToken(){
    return Cookies.remove('token')
}

export function setPermission(data) {
	Cookies.set('permission', data, {expires})
}
export function getPermission(){
	return Cookies.get('permission')
}

export function removePermission(){
    return Cookies.remove('permission')
}