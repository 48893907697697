import Vue from 'vue'
import Router from 'vue-router'
import { getToken } from '@/libs/auth'

Vue.use(Router)

import Main from '@/components/frame/main'
import Exam from '@/components/frame/exam'
import Practice from '@/components/frame/practice'
import Analysis from '@/components/frame/analysis'

const _import = require('./_import_' + process.env.NODE_ENV)
export const routes = [
	{ path: '*', redirect: '/login' },
	{
		path:'/login',
		component: _import('login'),
	},
	{
		path:'/home',
		redirect: '/home/index',
		component: Main,
		children: [
			{
				path: 'index',
				component: _import('index')
			},
			{
				path: 'task',
				component: _import('task')
			},
			{
				path: 'taskList',
				component: _import('taskList')
			},
			{
				path: 'AllTask',
				component: _import('staff/allTask')
			},
			{
				path: 'EditTask',
				component: _import('staff/editTask')
			},{
				path: 'user',
				component: _import('user')
			},{
				path: 'messages',
				component: _import('messages')
			},
			{
				path: 'addTask',
				component: _import('addTask')
			},
			{
				path:'setting',
				component: _import('setting')
			}
		]
	},{
		path:'/exam',
		redirect: '/exam/index',
		component: Exam,
		children: [
			{
				path: 'index',
				name: 'examIndex',
				component: _import('exam/index'),
			},
			{
				path: 'backlog',
				name: 'backlog',
				component: _import('exam/backlog')
			},{
				path: 'swiperList',
				name: 'swiperList',
				component: _import('exam/swiperList')
			},{
				path: 'indexModel',
				name: 'indexModel',
				component: _import('exam/indexModel')
			},{
				path: 'classifySet',
				name: 'classifySet',
				component: _import('exam/setting/classifySet')
			},{
				path: 'quickSet',
				name: 'quickSet',
				component: _import('exam/setting/quickSet')
			},{
				path: 'basicSet',
				name: 'basicSet',
				component: _import('exam/setting/basicSet')
			},{
				path: 'subject',
				name: 'subject',
				component: _import('exam/subject')
			},{
				path: 'theoryPapers',
				name: 'multPapers',
				component: _import('exam/multPapers')
			},{
				path: 'theoryDetail',
				name: 'multDetail',
				component: _import('exam/multDetail')
			},{
				path: 'specialPapers',
				name: 'specialPapers',
				component: _import('exam/specialPapers')
			},{
				path: 'specialDetail',
				name: 'specialDetail',
				component: _import('exam/specialDetail')
			},{
				path: 'classes',
				name: 'classes',
				component: _import('exam/classes')
			},{
				path: 'goodsCate',
				name: 'goodsCate',
				component: _import('exam/goodsCate')
			},{
				path: 'goodsList',
				name: 'goodsList',
				component: _import('exam/goodsList')
			},{
				path: 'ordersList',
				name: 'ordersList',
				component: _import('exam/ordersList')
			},{
				path: 'students',
				name: 'students',
				component: _import('exam/students'),
			},{
				path: 'courseLimit',
				name: 'students',
				component: _import('exam/student/sendCourse'),
			},{
				path: 'orders',
				name: 'orders',
				component: _import('exam/orders')
			},{
				path: 'purchase',
				name: 'purchase',
				component: _import('exam/purchase')
			},{
				path: 'studentDetail',
				name: 'studentDetail',
				component: _import('exam/student/studentDetail')
			},{
				path: 'classDetail',
				name: 'classDetail',
				component: _import('exam/classes/classDetail')
			}
		]
	},{
		path:'/practice',
		redirect: '/practice/students',
		component: Practice,
		children: [
			{
				path: 'index',
				name: 'practiceIndex',
				component: _import('practice/index'),
			},
			{
				path: 'exam',
				name: 'exam',
				component: _import('practice/exam')
			},
			{
				path: 'unit',
				name: 'unit',
				component: _import('practice/unit')
			},
			{
				path: 'editMicro',
				name: 'editMicro',
				component: _import('practice/editMicro')
			},
			{
				path: 'editPaper',
				name: 'editPaper',
				component: _import('practice/editPaper')
			},
			{
				path: 'classes',
				name: 'classes',
				component: _import('practice/classes')
			},
			{
				path: 'students',
				name: 'students',
				component: _import('practice/students'),
			},
			{
				path: 'basicSet',
				name: 'basicSet',
				component: _import('exam/setting/basicSet')
			},
			{
				path: 'activeList',
				name: 'activeList',
				component: _import('practice/activeList')
			},{
				path: 'swiperList',
				name: 'swiperList',
				component: _import('practice/swiperList')
			},{
				path: 'material',
				name: 'material',
				component: _import('practice/material')
			},{
				path: 'notice',
				name: 'notice',
				component: _import('practice/notice')
			},{
				path: 'question',
				name: 'question',
				component: _import('practice/question')
			},{
				path: 'answer',
				name: 'answer',
				component: _import('practice/answer')
			},{
				path: 'examination',
				name: 'examination',
				component: _import('practice/examination')
			},{
				path: 'editExamination',
				name: 'editExamination',
				component: _import('practice/editExamination')
			},{
				path: 'editExamPaper',
				name: 'editExamPaper',
				component: _import('practice/editExamPaper')
			}
		]
	},{
		path:'/analysis',
		redirect: '/analysis/students',
		component: Analysis,
		children: [
			{
				path: 'students',
				name: 'students',
				component: _import('analysis/students'),
			},
			{
				path: 'activeList',
				name: 'activeList',
				component: _import('analysis/activeList')
			},
			{
				path: 'materialList',
				name: 'materialList',
				component: _import('analysis/materialList')
			},
			{
				path: 'classList',
				name: 'classList',
				component: _import('analysis/classList')
			},
			{
				path: 'unitList',
				name: 'unitList',
				component: _import('analysis/unitList')
			},
			{
				path: 'gather',
				name: 'gather',
				component: _import('analysis/gather')
			}
		]
	}
]

const router = new Router({
	// mode: 'history',
	// linkActiveClass: 'active', // 设置路由激活菜单的class
	routes
})

router.beforeEach((to, from, next) => {
	if(!getToken() && to.path != '/login') {
		next({path: '/login', query: { redirect: to.fullPath }})
	}else{
		next()
	}
})

export default router
