<template>
    <a-layout class="main-layout">
        <a-layout-sider :trigger="null" collapsible v-model="collapsed" class="exam-sider">
            <div class="logo" >
                <img src="../../../public/image/indexLogo.png" alt="" v-if="!collapsed" />
                <img src="../../../public/image/sLogo.png" alt="" v-else style="width:40px;height:42px"/>
            </div>
            <a-menu mode="inline" :defaultOpenKeys="defaultOpenKeys" :selectedKeys="selectedKeys" class="menuList" @select="menuSelect">
                <!--<a-menu-item key="/practice/index">
                    <a-icon type="home" class="aicon"/>
                    <span>首页</span>
                </a-menu-item>-->
                <template v-for="item in menuList">
                    <template v-if="item.children">
                        <a-sub-menu :key="item.key">
                            <span slot="title"><a-icon :type="item.icon" /><span>{{item.title}}</span></span>
                            <a-menu-item v-for="el in item.children" :key="el.key">{{el.name}}</a-menu-item>
                        </a-sub-menu>
                    </template>
                    <template v-else>
                         <a-menu-item  :key="item.key" >
                            <a-icon :type="item.icon" class="aicon"/>
                            <span>{{item.title}}</span>
                        </a-menu-item>
                    </template>
                </template>
            </a-menu>
        </a-layout-sider>
        <a-layout>
        <a-layout-header class="topHeader">
            <div>
                <a-icon
                    class="trigger"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    @click="()=> collapsed = !collapsed"
                    :style="{ marginLeft: '10px' }"
                />
            </div>
            <div class="mainLink">
                <div @click="$router.push('/home')">系统管理</div>
                <div @click="pathToSys">考试系统</div>
                <div class="active">实践科目</div>
                <div @click="pathToAnaly">解析管理</div>
            </div>
            <div>
                <div class="headerMes">
                    <a-badge dot>
                        <a-icon type="bell" />
                    </a-badge>
                </div>
                <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link">
                        {{$store.getters.userInfo.username}}&nbsp;&nbsp;&nbsp;&nbsp;<a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                    <a-menu-item>
                        <a @click="logout">退出登录</a>
                    </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>

        </a-layout-header>
        <a-layout-content
            :style="{ overflowX:'hidden',height:'100%',display: 'flex', flexDirection: 'column'}"
        >
            <div class="navGroup">
                <span class="csP" @click="()=>{if($route.path != '/practice/index')$router.push('/practice/index')}">首页</span>
                <div class="linkTag csP" :class="{active: item.key == $route.path}" v-for="(item, index) in routelist" :key="index"><span @click="changeRoute(item.key)">{{item.name}}</span><a-icon type="close" @click="closePage(item, index)"/></div>
            </div>
            <router-view></router-view>
        </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { removeUserInfo, removeToken, removePermission } from '@/libs/auth'
export default {
    name:'layout',
    data(){
        return{
            collapsed: false,
            defaultOpenKeys: [],
            selectedKeys: [],
            menuList: [
                {
                    title: '学员管理',
                    icon: 'user',
                    key: '/practice/students',
                },
                {
                    title: '激活码管理',
                    icon: 'deployment-unit',
                    key: '/practice/activelist',
                },
                {
                    title: '考试管理',
                    icon: 'file-word',
                    key: '/practice/exam',
                },
                {
                    title: '其他管理',
                    icon: 'youtube',
                    key: 'sub2',
                    down: 1,
                    children: [
                        // {name: '科目列表',key: '/practice/subject'},
                        {name: '课程管理',key: '/practice/classes'},
                        {name: '资料管理',key: '/practice/material'},
                        {name: '问答管理',key: '/practice/question'},
                    ]
                },
                {
                    title: '通知信息',
                    icon: 'bell',
                    key: '/practice/notice',
                }
            ]
        }
    },
    computed:{
        ...mapGetters(['routelist'])
    },
    watch:{
        '$route'(val){
            if(val.path != '/practice/courseLimit'){
                this.selectedKeys = [val.path]
            }
        }
    },
    mounted(){
        this.selectedKeys = this.$route.path != '/practice/courseLimit'? [this.$route.path] : [ '/practice/students' ]
         this.menuList.forEach(item =>{
            if(item.children && item.children.filter(el=>el.key == this.$route.path).length){
                this.defaultOpenKeys.push(item.key)
            }
        })
    },
    methods:{
        ...mapMutations(['removeRoute', 'setRouteList']),
        menuSelect({key}){
            this.$router.push(key)
            if(key != '/practice/index'){
                if(this.routelist.findIndex(el=> el.key == key) == -1){
                    this.setRouteList(this.getRoute(key))
                }
            }
        },
        pathToSys(){
            localStorage.removeItem('routelist')
            this.$store.commit('removeAllRoute')
            this.$router.push('/exam');
        },
        pathToAnaly(){
            localStorage.removeItem('routelist')
            this.$store.commit('removeAllRoute')
            this.$router.push('/analysis');
        },
        getRoute(key){
            let result = {}
            if(key == '/practice/students'){
                result = {name: '学员管理', key: '/practice/students'}
            }else if(key == '/practice/exam'){
                result = {name: '考试管理', key: '/practice/exam'}
            }else if(key == '/practice/activelist'){
                result = {name: '激活码管理', key: '/practice/activeList'}
            }else if(key == '/practice/notice'){
                result = {name: '通知信息', key: '/practice/notice'}
            }else{
                this.menuList.forEach(item =>{
                    if(item.children && item.children.filter(el=>el.key == key).length){
                        result = item.children.filter(el=>el.key == key)[0]
                    }
                })
            }
            result.query = null
            return result
        },
        logout(){
            removeUserInfo()
            removeToken()
            removePermission()
            localStorage.removeItem('routelist');
            this.$store.commit('setUserInfo','')
            this.$store.commit('removeAllRoute')
            this.$router.push('/login')

        },
        closePage(item, index){
            this.$store.commit('removeRoute',item)
            if(this.$route.path == item.key)
                if(index == 0 ){
                    if(this.routelist.length == 0){
                        this.$router.push('/practice/index')
                    }else{
                        this.$router.push(this.routelist[this.routelist.length-1].key)
                    }

                }else{
                    this.$router.push(this.routelist[index-1].key)
                }
        },
        changeRoute(key){
            if(key != this.$route.path){
                if(this.$store.state.routelist.filter(el=>el.key == key)[0].query){
                    this.$router.push({path: key, query:this.$store.state.routelist.filter(el=>el.key == key)[0].query})
                }else{
                    this.$router.push(key)
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.linkTag{
    width: 110px;
    height: 30px;
    margin: 5px 20px 5px 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    border-radius: 3px;
    border:1px solid #888;
    color: #888;
    &.active{
        border:1px solid #ff5f4e;
        color: #ff5f4e;
    }
}
</style>
