<template>
  <a-layout class="main-layout">
    <a-layout-sider :trigger="null" collapsible v-model="collapsed" class="exam-sider">
      <div class="logo" >
        <img src="../../../public/image/indexLogo.png" alt="" v-if="!collapsed" />
        <img src="../../../public/image/sLogo.png" alt="" v-else style="width:40px;height:42px"/>
      </div>
      <a-menu mode="inline" :defaultOpenKeys="defaultOpenKeys" :selectedKeys="selectedKeys" class="menuList" @select="menuSelect">
        <a-menu-item key="/exam/index">
          <a-icon type="home" class="aicon"/>
          <span>首页</span>
        </a-menu-item>
        <template v-for="item in menuList">
          <template v-if="item.title != '学员管理'">
            <a-sub-menu :key="item.key">
              <span slot="title"><a-icon :type="item.icon" /><span>{{item.title}}</span></span>
              <a-menu-item v-for="el in item.children" :key="el.key">{{el.name}}</a-menu-item>
            </a-sub-menu>
          </template>
          <template v-else>
            <a-menu-item  :key="item.key" >
              <a-icon :type="item.icon" class="aicon"/>
              <span>{{item.title}}</span>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="topHeader">
        <div>
          <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="()=> collapsed = !collapsed"
              :style="{ marginLeft: '10px' }"
          />
        </div>
        <div class="mainLink">
          <div @click="$router.push('/home')">系统管理</div>
          <div class="active">考试系统</div>
          <div @click="pathToPc">实践科目</div>
          <div @click="pathToAnaly">解析管理</div>
        </div>
        <div>
          <div class="headerMes">
            <a-badge dot>
              <a-icon type="bell" />
            </a-badge>
          </div>
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link">
              {{$store.getters.userInfo.username}}&nbsp;&nbsp;&nbsp;&nbsp;<a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="logout">退出登录</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>

      </a-layout-header>
      <a-layout-content
          :style="{ overflowX:'hidden',height:'100%',display: 'flex', flexDirection: 'column'}"
      >
        <div class="navGroup">
          <span class="csP" @click="()=>{if($route.path != '/exam/index')$router.push('/exam/index')}">首页</span>
          <div class="linkTag csP" :class="{active: item.key == $route.path || ($route.path == '/exam/courseLimit' && item.key=='/exam/students')}" v-for="(item, index) in routelist" :key="index"><span @click="changeRoute(item.key)">{{item.name}}</span><a-icon type="close" @click="closePage(item, index)"/></div>
        </div>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { removeUserInfo, removeToken, removePermission } from '@/libs/auth'
export default {
  name:'layout',
  data(){
    return{
      collapsed: false,
      defaultOpenKeys: [],
      selectedKeys: [],
      menuList: [
        {
          title: '展示营销',
          icon: 'appstore',
          key: 'sub1',
          children: [
            {name: '轮播设置',key: '/exam/swiperList'},
            {name: '首页板块',key: '/exam/indexModel'},
          ]
        },{
          title: '题库管理',
          icon: 'hdd',
          key: 'sub2',
          children: [
            {name: '模拟考试',key: '/exam/theoryPapers'},
            {name: '专题项目',key: '/exam/specialPapers'},
            // {name: '科目管理',key: '/exam/subject'},
            // {name: '课程包管理',key: '/exam/classes'},
            // {name: '商品分类',key: '/exam/goodsCate'},
          ]
        },{
          title: '商城管理',
          icon: 'shopping-cart',
          key: 'sub3',
          children: [
            {name: '商品列表',key: '/exam/goodsList'},
            {name: '订单管理',key: '/exam/ordersList'}
          ]
        },
        {
          title: '学员管理',
          icon: 'user',
          key: '/exam/students',
        },
        {
          title: '订单管理',
          icon: 'profile',
          key: 'sub4',
          children: [
            {name: '购买记录',key: '/exam/purchase'},
            {name: '试卷记录',key: '/exam/orders'},
          ]
        },{
          title: '系统设置',
          icon: 'setting',
          key: 'sub5',
          children: [
            {name: '分类设置',key: '/exam/classifySet'},
            {name: '快捷设置',key: '/exam/quickSet'},
            {name: '系统设置',key: '/exam/basicSet'},
          ]
        }
      ]
    }
  },
  computed:{
    ...mapGetters(['routelist'])
  },
  watch:{
    '$route'(val){
      if(val.path != '/exam/courseLimit'){
        this.selectedKeys = [val.path]
      }
    }
  },
  mounted(){
    this.selectedKeys = this.$route.path != '/exam/courseLimit'? [this.$route.path] : [ '/exam/students' ]
    this.menuList.forEach(item =>{
      if(item.children && item.children.filter(el=>el.key == this.$route.path).length){
        this.defaultOpenKeys.push(item.key)
      }
    })
  },
  methods:{
    ...mapMutations(['removeRoute', 'setRouteList']),
    menuSelect({key}){
      this.$router.push(key)
      if(key != '/exam/index'){
        if(this.routelist.findIndex(el=> el.key == key) == -1){
          this.setRouteList(this.getRoute(key))
        }
      }
    },
    pathToPc(){
      localStorage.removeItem('routelist')
      this.$store.commit('removeAllRoute')
      this.$router.push('/practice');
    },
    pathToAnaly(){
      localStorage.removeItem('routelist')
      this.$store.commit('removeAllRoute')
      this.$router.push('/analysis');
    },
    getRoute(key){
      let result = {}
      if(key != '/exam/students'){
        this.menuList.forEach(item =>{
          if(item.children && item.children.filter(el=>el.key == key).length){
            result = item.children.filter(el=>el.key == key)[0]
          }
        })
      }else{
        result = {name: '学员管理', key: '/exam/students'}
      }
      result.query = null
      return result
    },
    logout(){
      removeUserInfo()
      removeToken()
      removePermission()
      localStorage.removeItem('routelist');
      this.$store.commit('setUserInfo','')
      this.$store.commit('removeAllRoute')
      this.$router.push('/login')

    },
    closePage(item, index){
      this.$store.commit('removeRoute',item)
      if(this.$route.path == item.key)
        if(index == 0 ){
          if(this.routelist.length == 0){
            this.$router.push('/exam/index')
          }else{
            this.$router.push(this.routelist[this.routelist.length-1].key)
          }

        }else{
          this.$router.push(this.routelist[index-1].key)
        }
    },
    changeRoute(key){
      if(key != this.$route.path){
        if(this.$store.state.routelist.filter(el=>el.key == key)[0].query){
          this.$router.push({path: key, query:this.$store.state.routelist.filter(el=>el.key == key)[0].query})
        }else{
          this.$router.push(key)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.linkTag{
  width: 110px;
  height: 30px;
  margin: 5px 20px 5px 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  border-radius: 3px;
  border:1px solid #888;
  color: #888;
  &.active{
    border:1px solid #ff5f4e;
    color: #ff5f4e;
  }
}
</style>
