import Vue from 'vue'
import App from './App.vue'

import Vuex from 'vuex'
Vue.use(Vuex)
import 'ant-design-vue/dist/antd.less'
import Antd from 'ant-design-vue'
Vue.use(Antd)



import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import _ from "lodash";
Vue.prototype._ = _

import $store from './store'
const store = new Vuex.Store($store)

Vue.config.productionTip = false

import '@/libs/chart.js'

import '@/assets/public.less'
import '@/assets/fonts/iconfont.js'
import '@/assets/fonts/iconfont.css'

import router from '@/router'

import draggable from 'vuedraggable'
Vue.component('draggable', draggable)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
